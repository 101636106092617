import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; 


SwiperCore.use([Navigation, Pagination, Autoplay, Navigation, EffectFade]);
function Banner3() {
  
  const { t, i18n } = useTranslation();

  const bannerSlider3 = {
    slidesPerView: 1,speed: 1200,spaceBetween: 15,effect: "fade",fadeEffect: {crossFade: true},autoplay: true,loop: true,roundLengths: true,pagination: {el: ".swiper-pagination",clickable: "true",},navigation: {nextEl: ".banner3-prev",prevEl: ".banner3-next"},
  };
  return (
    <>
      <div className="banner-section3">
        <img src='/images/bg/banner3-vector.svg' alt="image" className="banner3-vector" />
        <div className="banner-social-area-dark">
          <ul className="banner-social gap-4">
            <li><a href="https://www.facebook.com/"><i className="bx bxl-facebook"/></a></li>
            <li><a href="https://www.linkedin.com/company/allway-law-firm/"><i className="bx bxl-linkedin" /></a></li>
          </ul>
        </div>
        <div className="banner3-bg" />
        <div className="container-fluid">
          <div className="row">
            <Swiper {...bannerSlider3} className="swiper banner3-slider">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="bann31-single d-flex align-items-center">
                    <div className="content">
                      <span className="fs-3">{t('HomePage.welcomeMessage')}</span>
                      <h2>{t('HomePage.SteeringDisagreementstoSolutionsWith')} <span>{t('HomePage.ArbitrationLaw')}</span></h2>
                      <div className="button-group gap-5 d-flex justify-content-xl-start justify-content-center flex-md-nowrap flex-wrap">
                        <Link to={"/contact"}><a className="eg-btn btn--primary2 sibling2  btn--lg2"><i className="bi bi-dash-lg" />{t('HomePage.ContactNow')}<i className="bi bi-chevron-right" /></a></Link>
                      </div>
                    </div>
                    <div className="banner3-lawyer-img">
                      <img src='/images/lawyers/omar1.webp' className="img-fluid" alt="image" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="bann31-single d-flex align-items-center">
                    <div className="content">
                      <span className="fs-3">{t('HomePage.welcomeMessage')}</span>
                      <h2>{t('HomePage.CraftingBusinessSuccessWith')} <span>{t('HomePage.CommercialLaw')}</span></h2>
                      <div className="button-group gap-5 d-flex justify-content-xl-start justify-content-center flex-md-nowrap flex-wrap">
                        <Link to={"/contact"}><a className="eg-btn btn--primary2 sibling2  btn--lg2"><i className="bi bi-dash-lg" />{t('HomePage.ContactNow')}<i className="bi bi-chevron-right" /></a></Link>
                      </div>
                    </div>
                    <div className="banner3-lawyer-img">
                      <img src='/images/lawyers/omar1.webp' className="img-fluid" alt="image" />
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
        <div className="slider-arrows banner3-arrows text-center d-md-flex d-none flex-row justify-content-center align-items-center gap-5">
          <div className="banner3-prev swiper-prev-arrow style-3" tabIndex={0} role="button" aria-label="Previous slide"><img src='/images/icons/arr-prev.svg' alt="image"/></div>
          <div className="banner3-next swiper-next-arrow style-3" tabIndex={0} role="button" aria-label="Next slide"><img src='/images/icons/arr-next.svg' alt="image"/></div>
        </div>
      </div>
    </>
  );
}

export default Banner3;
