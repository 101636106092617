import React , {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { getDataForLanguage } from "../../../helpers/LanguageHelper";
import { useTranslation } from "react-i18next";

function Blog3() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  

  useEffect(() => {
      window.scrollTo(0, 0); 
    const fetchData = async () => {
      const data = await getDataForLanguage('blog');
      setData(data.default);
      setLoading(false);
    };
    
    fetchData();
  }, [i18n.language]);
  return (
    
    <>
    {loading ? (
        <Preloader style="home2preloader" />
        ) : ( 
     <div className="blog-section pb-120 dark-mode-bg pt-120">
      <div className="container-fluid">
        <div className="row">
          <div className="section-title-area sibling2">
            <div className="marquee">
              <div><span>{t('HomePage.Articale Post')}</span><span>{t('HomePage.Articale Post')}</span></div>
            </div>
            <div className="section-title sibling2"><span>{t('HomePage.LATEST BLOG')}</span><h2 className="text-white">{t('HomePage.Read and Learn from our Daily articles')}</h2></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center g-4">
            {data.map((i) => {
                return (
                  <div key={i.id + 4896} className="col-lg-6 col-md-6 col-sm-10 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="200ms">
                    <div className="blog-single2">
                      <div className="image">
                        <span className="blog-badge">By, {i.author}</span>
                        <Link to={`/${i.linkName}`}><img src={`/images/blog/${i.image}`} className="img-fluid" alt="image" /></Link>
                      </div>
                      <div className="content">
                        <ul className="post-meta-list">
                          <li>{i.subject}</li>
                          <li>{i.date}</li>
                        </ul>
                        <h5><Link to={`/${i.linkName}`}>{i.brief}</Link></h5>
                      </div>
                    </div>
                  </div>
                );
            })} 
        </div>
      </div>
    </div>
   )}
    </>
  )
}

export default Blog3