import React , {useEffect} from "react";
import { useParams } from "react-router-dom";
import practiceData from "../../data/practice_data.json";
import Layout from "../partials/layout/Layout";
import Breadcrumb from "../common/Breadcrumb";
import DetailsPractice from "../partials/practice/DetailsPractice";

const PracticeDetails = () => {
    const { username } = useParams();
    const practiceArea = practiceData.find(data => data.link === username);
    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);
    return (
      <> 
          <Layout>
              <Breadcrumb title="Practice Area Details" pageName={practiceArea.title} />
              <DetailsPractice/>
          </Layout>
      </>
    )
}

export default PracticeDetails