import React, { useState, useEffect } from "react";
import Layout from "../partials/layout/Layout";
import Breadcrumb from "../common/Breadcrumb";
import { Link } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getDataForLanguage } from "../../helpers/LanguageHelper";
import i18n from "i18next";
import { useTranslation } from 'react-i18next'; 

const Blog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();


  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await getDataForLanguage("blog");
      setData(data.default);
      setLoading(false);
    };

    fetchData();
  }, [i18n.language]);
  return (
    <>
      {loading ? (
        <Preloader style="home2preloader" />
      ) : (
        <Layout>
          <Breadcrumb title={t('blog.page title')} pageName={t('blog.page title')} />
          <div className="blog-grid-section pt-120 pb-120">
            <div className="container">
              <div className="row justify-content-center">
                {data.map((i) => {
                  return (
                    <div
                      key={i.id}
                      className="blog-single1 p-0 style-2 col-lg-6 col-md-6 col-sm-10"
                    >
                      <div className="image">
                        <span className="blog-badge">{i.subject}</span>
                        <Link to={`/${i.linkName}`}>
                          <img
                            src={`/images/blog/${i.image}`}
                            className="img-fluid"
                            alt="image"
                          />
                        </Link>
                      </div>
                      <div className="text">
                        <div className="post-meta">
                          <div className="date">{i.date}</div>
                        </div>
                        <h4>
                          <Link to={`/${i.linkName}`}>{i.brief}</Link>
                        </h4>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Blog;
