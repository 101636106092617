import React ,{useEffect} from "react";
import Layout from "../partials/layout/Layout";
import Breadcrumb from "../common/Breadcrumb";
import About1 from "../partials/about/About1";
import Counter2 from "../common/Counter2";
import About2 from "../partials/about/About2";
import AboutContactArea from "../partials/contact/AboutContactArea";
import { useTranslation } from 'react-i18next'; 


const About = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
      window.scrollTo(0, 0); 
  }, []);
  return (
    <>
        <Layout>
            <Breadcrumb title={t('about.page title')} pageName={t('about.page title')}/>
            <About1 />
            <Counter2 />
            <About2 />
            <AboutContactArea />
        </Layout>
    </>
  )
}

export default About