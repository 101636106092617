import React , {useEffect} from "react";
import Layout from "../partials/layout/Layout";
import Breadcrumb from "../common/Breadcrumb";
import AttornyTeam from "../partials/Attrony/AttornyTeam";
import { useTranslation } from 'react-i18next'; 


const Team = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
      window.scrollTo(0, 0); 
  }, []);
  return (
    <>
        <Layout>
            <Breadcrumb title={t('ourteam.page title')} pageName={t('ourteam.page title')} />
            <AttornyTeam/>
        </Layout>
    </>
  )
}

export default Team