import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { getDataForLanguage } from "../../../helpers/LanguageHelper";
import { useTranslation } from "react-i18next";

const PracticeArea = () => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDataForLanguage("practice");
      setData(data.default);
    };

    fetchData();
  }, [i18n.language]);

  return (
    <>
      <div className="practice-area-section pb-120 pt-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div className="section-title1 text-center">
                <h2>{t('practice.Practice Area')}</h2>
                <p>
                {t('practice.paragraph')}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4">
            {data.map((data, i) => {
              return (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10">
                  <div
                    className={`practice-single  wow animate fadeInDown`}
                    data-wow-duration="1.5s"
                    data-wow-delay="0.4s"
                  >
                    <div className="header">
                      <div className="icon-area">
                        <Link to={`/practice/${data.link}`}>
                          <img
                            alt="image"
                            width={55}
                            height={55}
                            src={`/images/icons/practiceAreaIcon/${data.image}`}
                          />
                        </Link>
                      </div>
                      <h5>
                        <Link to={`/practice/${data.link}`}>{data.title}</Link>
                      </h5>
                    </div>
                    <div className="body">
                      <p className="practice-area-paragraph">{data.brief}</p>
                      <Link
                        to={`/practice/${data.link}`}
                        className="details-btn"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PracticeArea;
