
import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Link } from "react-router-dom";
import { getDataForLanguage } from "../../../helpers/LanguageHelper";
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation, Pagination, Autoplay, Navigation, EffectFade]);

function Practice3() {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDataForLanguage('practice');
      setData(data.default);
    };

    fetchData();
  }, [i18n.language]);

  const practiceSlider = {
    slidesPerView: "auto",
    speed: 1200,
    spaceBetween: 25,
    autoplay: true,
    loop: true,
    roundLengths: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: 'true',
    },
    navigation: {
      nextEl: '.service-prev1',
      prevEl: '.service-next1',
    },
    breakpoints: {
      280: {
        slidesPerView: 1,
        navigation: false,
      },
      576: {
        slidesPerView: 2,
        navigation: false,
      },
      768: {
        slidesPerView: 2,
        navigation: false,
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        slidesPerView: 3
      },
    }
  }
  return (
    <>
      <div className="practice-area-section pt-120 pb-120 dark-mode-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="section-title-area sibling2">
              <div className="marquee">
                <div>
                  <span>{t('HomePage.PracticeArea')}</span>
                  <span>{t('HomePage.PracticeArea')}</span>
                </div>
              </div>
              <div className="section-title sibling2">
                <span>{t('HomePage.PracticeArea')}</span>
                <h2 className="text-white">{t('HomePage.Expertise in diverse fields for your security.')}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center g-4">
            <div className="col-12">
              <Swiper {...practiceSlider} className="swiper practice3-slider pb-65">
                <div className="swiper-wrapper">
                  {data.map((data, i) => {
                    return (
                      <SwiperSlide key={i} className="swiper-slide wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.2s">
                        <div className="practice-single3">
                          <div className="practice-image">
                            <img alt="" width={55} height={55} src={`/images/icons/practiceAreaIcon/${data.image}`} />                            <div className="practice-icon">
                              <Link to={`/practice/${data.link}`}><img alt="" width={55} height={55} src={`/images/icons/practiceAreaIcon/${data.image}`} /></Link>
                            </div>
                          </div>
                          <div className="practice-content">
                            <h4><Link to={`/practice/${data.link}`}>{data.title}</Link></h4>
                            <p className="para">{data.brief}</p>
                            <Link to={`/practice/${data.link}`} className="details-btn">{t('HomePage.LearnMore')}</Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </div>
                <div className="swiper-pagination d-flex align-items-center justify-content-center" />
              </Swiper>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Practice3