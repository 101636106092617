import React from 'react'
import { useTranslation } from 'react-i18next'; // Import useTranslation
import i18n from 'i18next';
function History3() {
  const { t, i18n } = useTranslation();
  return (
    <>
     <div className="history-section dark3-bg pt-120 pb-100">
  <div className="container-fluid">
    <div className="row">
      <div className="section-title-area">
        <div className="marquee">
          <div>
            <span>{t("HomePage.Collaboration Announcement")}</span>
            <span>{t("HomePage.Collaboration Announcement")}</span>
          </div>
        </div>
        <div className="section-title">
          <span className='fs-5'>{t("HomePage.Collaboration Announcement")}</span>
          <h2 className='fs-5'>{t("HomePage.We're thrilled to announce a strategic collaboration between AllWay Law Firm LLP (Lebanon) & Zeynep Avcı Law Firm (Turkey). This alliance expands our legal expertise, enabling us to provide comprehensive international services, especially for all our clients from Arabic nations who seek legal services in Turkey.")}</h2>
        </div>
        <hr className="m-0" />
      </div>
    </div>
    <div className="row justify-content-center padding-x g-4">
      <div className="col-lg-4 col-md-6 col-sm-10">
        <div className="history-card">
          <span className="date-badge">{t("HomePage.1")}</span>
          <h3>{t("HomePage.Property Law")}</h3>
          <p>{t("HomePage.We provide all legal support for those who are property owners in Turkey.")}</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-10">
        <div className="history-card">
          <span className="date-badge">{t("HomePage.2")}</span>
          <h3>{t("HomePage.Coorporate Law")}</h3>
          <p>{t("HomePage.Establish all kinds of companies and different commercial institutions.")}</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-10">
        <div className="history-card mb-0">
          <span className="date-badge">{t("HomePage.3")}</span>
          <h3>{t("HomePage.Citizenship Law")}</h3>
          <p>{t("HomePage.Acquire citizenship for foreigners who are eligible to get Turkish citizenship and help other foreigners in obtaining residency.")}</p>
        </div>
      </div>
      <div className='col-lg-6 col-12 mx-auto mt-5 mb-4 pt-3 text-center'><p className='text-white fs-4'>{t("HomePage.We look forward to a successful future together! Don't hesitate to contact us for more information regarding the services that we can provide in Turkey.")}</p></div>
    </div>
  </div>
</div>
   
    </>
  )
}

export default History3