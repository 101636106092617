import React from "react";
import { useTranslation } from 'react-i18next'; 

function About1() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="about-section pt-120 pb-120" id="about">
        <img src="/images/bg/section-bg1.svg" alt="image" className="section-bg1 img-fluid" />
        <img src="/images/bg/section-bg1.svg" alt="image" className="section-bg1 img-fluid" />

        <div className="container">
          <div className="row ">
            <div
              className="col-12 text-lg-start text-center wow fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="200ms"
            >
              <div className="icon d-block d-lg-none mb-3">
                <img src="/images/icons/header1-logo.webp" alt="image" className="img-fluid" />
              </div>
              <div className="section-title1">
                <ul className="feature-list section-title1">
                  <li>
                    <div className="icon d-none d-lg-block">
                      <img src="/images/icons/header1-logo.webp" alt="image" className="img-fluid" />
                    </div>
                    <div className="text">
                      <h2>{t('about.About AllWay Law Firm LLP')}</h2>
                      <p className="para">
                      {t('about.paragraph1')}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="section-title1">
                <h2 className="fs-2">{t('about.Our Expertise')}</h2>
                <p className="mt-3">
                {t('about.paragraph2')}
                </p>
                <p className="mt-2">
                {t('about.paragraph3')}
                </p>
              </div>
              <div className="section-title1">
                <h2 className="fs-2">{t('about.Client-Centered Approach')}</h2>
                <p className="mt-3">
                {t('about.paragraph4')}
                </p>
              </div>
              <div className="section-title1">
                <h2 className="fs-2">{t('about.Resolving Disputes Effectively')}</h2>
                <p className="mt-3">
                {t('about.paragraph5')}
                </p>
              </div>
              <div className="section-title1">
                <h2 className="fs-2">{t('about.Committed to Excellence')}</h2>
                <p className="mt-3">
                {t('about.paragraph6')}

                </p>
              </div>
              <div className="section-title1">
                <h2 className="fs-2">{t('about.Committed to Excellence')}</h2>
                <p className="mt-3">
                {t('about.paragraph7')}
                </p>
              </div>
              <div className="section-title1">
                <h2 className="fs-2">{t('about.Contact Us')}</h2>
                <p className="mt-3">
                {t('about.paragraph8')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/images/bg/banner4-vcrt.svg"
          alt="image"
          className="section-bg2 img-fluid d-lg-block d-none "
        />
        <img
          src="/images/bg/banner4-vcrt.svg"
          alt="image"
          className="section-bg2 img-fluid d-lg-block d-none"
        />
        <img
          src="/images/bg/banner4-vcrt.svg"
          alt="image"
          className="section-bg2 img-fluid d-lg-block d-none"
        />
        <img
          src="/images/bg/banner4-vcrt.svg"
          alt="image"
          className="section-bg2 img-fluid d-lg-block d-none"
        />
        <img
          src="/images/bg/banner4-vcrt.svg"
          alt="image"
          className="section-bg2 img-fluid d-lg-block d-none"
        />
      </div>
    </>
  );
}

export default About1;
