import React from 'react'
import i18n from 'i18next';
import { useTranslation } from 'react-i18next'; 
function About3() {
  const { t, i18n } = useTranslation();
  return (
    <>
     <div className="about-section pb-120">
  <img src='/images/bg/section-bg1.svg' alt="image" className="section-bg1 img-fluid" />
  <div className="container">
    <div className="row gy-5">
      <div className="col-xl-6 text-lg-start d-sm-flex d-none justify-content-center wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="200ms">
        <div className="about3-image-area">
          <img src='/images/bg/aboutHomepage.webp' className="about3-image" alt="image" />
          <span className="years py-4 mt-2"><img alt="image" width={60} src='/images/icons/header1-logo.webp' /></span>
        </div>
      </div>
      <div className="col-xl-6 d-flex justify-content-xl-end flex-column justify-content-center text-xl-start text-center">
        <div className="section-title-area sibling3">
          <div className="marquee">
            <div>
              <span>{t("HomePage.About AllWay")}</span>
              <span>{t("HomePage.About AllWay")}</span>
            </div>
          </div>
          <div className="section-title text-xl-start text-center">
            <span>{t("HomePage.GET TO KNOW US")}</span>
            <h2>{t("HomePage.Learn About Our Firm")}</h2>
          </div>
        </div>
        <div className="about3-content mb-5">
          <p className="para">{t("HomePage.AllWay Law Firm LLP in Lebanon provides global legal services. Our expertise ranges from Corporate to Construction Law. We navigate cross-border complexities with a client-centered approach. Our commitment to excellence and ethical standards makes us your trusted legal partner.")}</p>
          <ul className="about3-list">
            <li>{t("HomePage.Upholding ethical integrity in all actions.")}</li>
            <li>{t("HomePage.Constant learning to stay at the legal forefront.")}</li>
            <li>{t("HomePage.Personalized attention to every case.")}</li>
           
          </ul>
          <div className="d-flex justify-content-xl-start justify-content-center align-items-center flex-wrap gap-4">
            <div className="about3-author border-0">
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
   
    </>
  )
}

export default About3