import React , {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import Layout from "../partials/layout/Layout";
import Breadcrumb from "../common/Breadcrumb";
import AttornyDetails from "../partials/Attrony/AttornyDetails";

import attronyData from "../../data/attrony_data.json";
const TeamDetails = () => {
  useEffect(() => {
      window.scrollTo(0, 0); 
  }, []);
    const { username } = useParams();
    const teamMember = attronyData.find(member => member.linkName === username);

  return (
    <>
        <Layout>
            <Breadcrumb title={teamMember.name} pageName={teamMember.name} />
            <AttornyDetails/>
        </Layout>
    </>
  )
}

export default TeamDetails