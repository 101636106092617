import React from "react";
import { useTranslation } from 'react-i18next'; 

function About2() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="about-section2 pt-120 pb-120">
        <div className="container">
          <div className="row g-4 justify-content-center align-items-center">
            <div className="col-lg-6">
            <div className="about2-image-area">
              <img src='/images/bg/aboutUsEnd.webp' className="about2-image" alt="image" />
            </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title2 sibling3 text-lg-start text-center">
                <span>{t('about.Best Experience')}</span>
                <h2>
                {t('about.Find Us What We Actually Do For Giving Complete Solutions')}
                </h2>
              </div>
              <div className="about2-counter-area text-lg-start text-center">
                <p className="para">
                {t('about.paragraph9')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About2;
