import React from "react";
import LayoutHeader from "../header/LayoutHeader";
import Footer from "../../common/Footer";
import Topbar from "../../common/Topbar";
function Layout({ children }) {
  return (
    <>
      <Topbar />
      <LayoutHeader />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
