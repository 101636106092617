import React , {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { getDataForLanguage } from "../../../helpers/LanguageHelper";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next'; // Import useTranslation



function Attrony3() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDataForLanguage('lawyer');
      setData(data.default);
      setLoading(false);
    };
    
    fetchData();
  }, [i18n.language]);
  return (
    <>{loading ? (
      <Preloader style="home2preloader" />
      ) : ( 
     <div className="attorneys-section pt-120 pb-120 dark-mode-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="section-title-area sibling2">
              <div className="marquee">
                <div>
                  <span className="text-uppercase">{t('HomePage.Creative Team')}</span>
                  <span className="text-uppercase">{t('HomePage.Creative Team')}</span>
                </div>
              </div>
              <div className="section-title sibling2">
                <span>{t('HomePage.Creative Team')}</span>
                <h2 className="text-white">{t('HomePage.We have the most experienced lawyers')}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
                <div className="swiper-wrapper row">
                  {data.map((res,i)=>{
                    return(
                      <div className="attorney-single sibling3 col-lg-4 col-md-6 col-10 my-4" key={i*80}>
                        <img src={`/images/lawyers/${res.image}`} className="casestudy1" alt="image" />
                        <div className="content">
                          <h4><Link to={`/team/${res.linkName}`}>{res.name}</Link></h4>
                          <p>{res.position}</p>
                        </div>
                        <ul className="social-list2 gap-3">
                          <li><a href={res.linkedin}><i className="bx bxl-linkedin" /></a></li> 
                        </ul>
                      </div>   
                    );
                  })}
                </div>
            </div>
          </div>
        </div>
      </div>)}
    </>
  )
}

export default Attrony3