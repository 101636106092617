import React , {useEffect} from "react";
import Layout from "../partials/layout/Layout";
import Breadcrumb from "../common/Breadcrumb";
import PracticeArea from "../partials/practice/PracticeArea";
import History3 from "../partials/home3/History3"
import { useTranslation } from 'react-i18next'; 



const Practice = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    return (
        <> 
            <Layout>
                <Breadcrumb title={t('practice.page title')} pageName={t('practice.page title')} />
                <PracticeArea/>
                <History3/>
            </Layout>
        </>
    )
}

export default Practice