import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { getDataForLanguage } from "../../../helpers/LanguageHelper";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const AttornyTeam = () => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDataForLanguage("lawyer");
      setData(data.default);
      setLoading(false);
    };

    fetchData();
  }, [i18n.language]);
  return (
    <>
      {loading ? (
        <Preloader style="home2preloader" />
      ) : (
        <div className="team-section pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center mb-60">
              <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                <div className="section-title2 text-center">
                  <span>{t("ourteam.Our Attorneys")}</span>
                  <h2>{t("ourteam.Find Our Intellectual Lawyer For You.")}</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center g-4 mb-60">
              {data.map((i) => {
                return (
                  <div key={i.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-10  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.2s" >
                    <div className="attorney-single sibling3">
                      <img
                        src={`/images/lawyers/${i.image}`}
                        className="casestudy1"
                        alt="image"
                      />
                      <div className="content">
                        <h4><Link to={`/team/${i.linkName}`}>{i.name}</Link></h4>
                        <p><Link to={`/team/${i.linkName}`}>{i.position}</Link></p>
                      </div>
                      <ul className="social-list2 gap-3">
                        <li><a href={i.linkedIn} target="_blank"><i className="bx bxl-linkedin" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttornyTeam;
