import React , {useState,useEffect} from "react";
import { Link ,useParams } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { getDataForLanguage } from "../../../helpers/LanguageHelper";
import i18n from 'i18next';

const DetailsPractice = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [singleData , setSingleData] = useState({});
    const [descriptionLines , setDescriptionLines] = useState([]);
    const { username } = useParams();

    useEffect(() => {
      
      const fetchData = async (username) => {
        const data = await getDataForLanguage('practice');
        const practiceArea = data.default.find(res => res.link === username);
        setData(data.default);
        setSingleData(practiceArea)
        setDescriptionLines(practiceArea.paragraph.split('\n'));
        setLoading(false);
      };
      
      fetchData(username);
      window.scrollTo(0, 0); 
    }, [i18n.language,username]);
  return (
    <>
      {loading ? (
        <Preloader style="home2preloader" />
        ) : (  <div className="service-details pt-120 pb-80">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-8 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="0.2s" >
                <div className="service-details-text mb-5">
                  <h2>{singleData.title}</h2>
                    {descriptionLines.map((line, index) => (
                        <p className="para" key={index}>{line}</p>
                    ))}
                </div>
              </div>
              <div className="col-lg-4 wow fadeInRight d-none d-lg-block" data-wow-duration="1.5s" data-wow-delay="0.2s" >
                <ul className="service-list">
                {data.map((data) => {
                    return (
                        <ServiceList title={data.title} link={data.link}/>
                    );
                })}
                 </ul>
              </div>
            </div>
            <div className="row g-4">
              <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="0.2s" >
                <h3 className="feature-title">We Provide</h3>
                <ul className="feature-list">
                  <FeatureList
                    image='/images/icons/feature-icon1.svg'
                    title={singleData.provideTitle1}
                    details={singleData.provideDesc1}
                  />
                  <FeatureList
                    image='/images/icons/feature-icon2.svg'
                    title={singleData.provideTitle2}
                    details={singleData.provideDesc2}
                  />
                  <FeatureList
                    image='/images/icons/feature-icon3.svg'
                    title={singleData.provideTitle3}
                    details={singleData.provideDesc3}
                  />
                </ul>
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="0.2s" >
                <img src={`/images/practiceArea/${singleData.image2}`} alt="image" className="img-fluid mb-0" />
              </div>
            </div>
            <div className="row mt-5 d-block d-lg-none">
                <div className="col-lg-4 wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="0.2s" >
                    <ul className="service-list">
                    {data.map((d) => {
                        return (
                            <ServiceList title={d.title} link={d.link}/>
                        );
                    })}
                    </ul>
                </div>
            </div>
          </div>
        </div> )}
    </>
  )
}

export default DetailsPractice

function ServiceList({ title, link }) {
    return (
      <>
      <Link to={`/practice/${link}`}>
        <li>
          <Link to={`/practice/${link}`}>{title}</Link>
          <span>
            <svg width={18} height={15} viewBox="0 0 22 13" xmlns="http://www.w3.org/2000/svg" >
              <path d="M21.9805 6.64708C21.955 6.74302 20.6834 7.78829 18.0766 9.85862C13.9311 13.156 14.0201 13.0954 13.5751 12.949C13.1809 12.8177 13.0219 12.5097 13.1809 12.1814C13.2127 12.1057 14.6369 10.9342 16.3408 9.5809L19.4309 7.11669V5.90479L16.3091 3.41534C14.23 1.75907 13.1682 0.885493 13.1427 0.789551C13.041 0.466377 13.2635 0.143203 13.6577 0.0472607C13.7595 0.0270623 13.8485 0.00181433 13.8612 0.00181433C14.0201 -0.0385824 14.8467 0.582518 18.1148 3.18306C20.6898 5.23824 21.955 6.27846 21.9805 6.36935C22.0059 6.45015 22.0059 6.57134 21.9805 6.64708Z" fill="white" />
              <path d="M17.4313 5.90479V7.11669L2.71236 7.10659C2.27365 7.10608 1.84766 7.10558 1.43438 7.10507C1.19278 7.10507 0.954985 7.10457 0.721643 7.10457C0.320448 7.09396 0 6.83189 0 6.51074C0 6.34662 0.0839268 6.19817 0.218718 6.09061C0.349695 5.98659 0.528993 5.92044 0.728001 5.9169L1.23283 5.9164L2.706 5.91488L17.4313 5.90479Z" fill="white" />
            </svg>
          </span>
        </li>
        </Link>
      </>
    );
}

function FeatureList({ image, title, details }) {
    return (
      <>
        <li>
          <div className="icon">
            <img src={image} alt="image" />
          </div>
          <div className="text">
            <h4>{title}</h4>
            <p className="para">{details}</p>
          </div>
        </li>
      </>
    );
}
  