import React, { useEffect, useState } from "react";
import Header3 from "../partials/header/Header3";
import Banner3 from "../partials/banner/Banner3";
import Footer from "../common/Footer";
import Preloader from "../common/Preloader";
import Practice3 from "../partials/practice/Practice3";
import About3 from "../partials/about/About3"
import History3 from "../partials/home3/History3"
import Attrony3 from "../partials/Attrony/Attrony3"
import Contact3 from "../partials/contact/Contact3"
import Blog3 from "../partials/blog/Blog3"

const Homepage = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0); 
        setLoading(false);
        setTimeout(() => {
        setLoading(false);
        }, 3000);
    }, []);

  return (

    <div className="dark-mode-bg">
    {loading ? (
      <Preloader style="home2preloader" />
    ) : (
        <>
        <Header3/>
        <Banner3/>
        <Practice3/>
        <About3/>
        <History3/>
        <Attrony3/>
        <Blog3/>
        <Contact3/>
        <Footer/>
        </>
    )}
    </div>
  )
}

export default Homepage