import React, { useEffect } from "react";
import Layout from "../partials/layout/Layout";
import Breadcrumb from "../common/Breadcrumb";
import { useTranslation } from 'react-i18next'; 

const Careers = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout>
        <Breadcrumb title={t('careers.page title')} pageName={t('careers.page title')} />
        <div className="about-section pt-120 pb-120" id="about">
          <img src="/images/bg/section-bg1.svg" alt="image" className="section-bg1 img-fluid" />
          <img src="/images/bg/section-bg1.svg" alt="image" className="section-bg1 img-fluid" />

          <div className="container">
            <div className="row ">
              <div
                className="col-12 text-lg-start text-center wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="200ms"
              >
                <div className="icon d-block d-lg-none mb-3">
                  <img src="/images/icons/header1-logo.webp" alt="image" className="img-fluid" />
                </div>
                <div className="section-title1">
                  <ul className="feature-list section-title1">
                    <li>
                      <div className="icon d-none d-lg-block">
                        <img src="/images/icons/header1-logo.webp" alt="image" className="img-fluid" />
                      </div>
                      <div className="text">
                        <h2 className="fs-2">
                        {t('careers.Welcome to the AllWay Law Firm LLP Careers page.')}
                        </h2>
                        <p className="para fs-5">
                        {t('careers.paragraph1')}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="section-title1">
                  <p className="mt-3 fs-5">
                    {" "}
                    {t('careers.paragraph2')}
                  </p>
                </div>
                <div className="section-title1">
                  <ul className="feature-list section-title1">
                    <li>
                      <div className="text">
                        <h2 className="fs-2">{t('careers.How To Apply')}</h2>
                        <p className="para fs-5">
                        {t('careers.Please send your cv to info@allwaylaw.com with the Subject Careers')}
                        </p>

                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/images/bg/banner4-vcrt.svg"
            alt="image"
            className="section-bg2 img-fluid d-lg-block d-none "
          />
          <img
            src="/images/bg/banner4-vcrt.svg"
            alt="image"
            className="section-bg2 img-fluid d-lg-block d-none"
          />
          <img
            src="/images/bg/banner4-vcrt.svg"
            alt="image"
            className="section-bg2 img-fluid d-lg-block d-none"
          />
          <img
            src="/images/bg/banner4-vcrt.svg"
            alt="image"
            className="section-bg2 img-fluid d-lg-block d-none"
          />
          <img
            src="/images/bg/banner4-vcrt.svg"
            alt="image"
            className="section-bg2 img-fluid d-lg-block d-none"
          />
        </div>
      </Layout>
    </>
  );
};

export default Careers;

// Welcome to the AllWay Law Firm LLP Careers page.
// We're passionate about the law and committed to providing exceptional service to our clients. Our diverse team of legal experts embodies our core values of integrity, dedication, and innovation.

// At AllWay Law Firm, we believe that our strength lies in our people. We foster an inclusive work environment that values each individual's contribution towards achieving our firm's goals.
// Whether you're a seasoned attorney, a budding lawyer, or an aspiring paralegal, AllWay Law Firm offers a platform where you can grow, learn, and make a significant impact.
// Our commitment to professional development ensures that our team stays ahead of the legal curve. We offer mentorship programs, regular training, and resources that equip our team with the knowledge and skills to excel in their roles.
// We invite you to join our dynamic team and contribute to our mission of delivering comprehensive legal solutions to our clients.
// If you're ready to embark on a rewarding career with AllWay Law Firm, browse through our current job openings or submit your CV for future opportunities. We look forward to your contribution to our team's diversity and success.
// Join us in shaping the future of law. At AllWay Law Firm, your career journey is always on the right track.
