import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { getDataForLanguage } from "../../../helpers/LanguageHelper";
import i18n from "i18next";

const AttornyDetails = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [descriptionLines, setDescriptionLines] = useState([]);
  const { username } = useParams();

  useEffect(() => {
    const fetchData = async (username) => {
      const data = await getDataForLanguage("lawyer");
      const lawyer = data.default.find((res) => res.linkName === username);
      setData(lawyer);
      setDescriptionLines(lawyer.description.split("\n"));
      setLoading(false);
    };

    fetchData(username);
  }, [i18n.language, username]);
  return (
    <>
      {loading ? (
        <Preloader style="home2preloader" />
      ) : (
        <div className="lawyer-details-section pt-120 pb-120">
          <div className="container">
            <div className="lawyer-info">
              <div className="row gy-5">
                <div className="col-xl-5 text-xl-start text-center">
                  <div className="row">
                    <div className="col-12 text-xl-start text-center">
                      <img src={`/images/lawyers/${data.image}`} className="img-fluid rounded-2" alt="image" />
                    </div>
                    <div className="col-12 text-xl-start text-center mt-4 d-none d-xl-block">
                      <div className="lawyer-short-details">
                        <h4>Short Details</h4>
                        <ul className="details-list">
                          <li><span>Position: </span><h5>{data.position}</h5></li>
                          <li><span>Email: </span><h5>{data.email}</h5></li>
                          <li><span>Nationality: </span><h5>{data.nationality}</h5></li>
                          <li>
                            <ul className="lawyer-social pt-2">
                              <li><a href={data.linkedin}><i className="bx bxl-linkedin" /></a></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-7">
                  <div className="lawyer-profile text-start ">
                    <h2>{data.name}</h2>
                    <span>{data.position}</span>
                    {descriptionLines.map((data, i) => {
                      return (
                        <p className="para" key={i}>{data}</p>
                      );
                    })}
                  </div>
                </div>

                <div className="col-12 text-xl-start text-center mt-4 d-block d-xl-none">
                  <div className="lawyer-short-details">
                    <h4>Short Details</h4>
                    <ul className="details-list">
                      <li><span>Position: </span><h5>{data.position}</h5></li>
                      <li><span>Email: </span><h5>{data.email}</h5></li>
                      <li><span>Nationality: </span><h5>{data.nationality}</h5> </li>
                      <li>
                        <ul className="lawyer-social pt-2">
                          <li><a href={data.linkedin}><i className="bx bxl-linkedin" /></a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-4">
              <div className="col-lg-12 mb-4 text-lg-start text-center">
                <h3 className="lawyer-edu-title">General Education</h3>
                <ul className="lawyer-edu-list">
                  {data.education.map((edu, i) => {
                    return (
                      <li key={i * 12}><span>{edu.title}</span>{edu.description}</li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="row g-4">
              <div className="col-lg-12 mb-4 text-lg-start text-center">
                <h3 className="lawyer-edu-title">Practice Areas</h3>
                <div className="row">
                  {data.practiceAreas.map((edu, i) => {
                    return (
                      <div className="col-xl-4 col-md-6 col-12 my-2">
                        <ul className="lawyer-edu-list">
                          <li key={i * 12}><span>{edu}</span></li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttornyDetails;
