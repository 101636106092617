import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import useTranslation



function Topbar() {
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language
  const { t, i18n } = useTranslation();
  return (
    <>
     <div className="topbar">
  <div className="container-fluid">
    <div className="row align-items-center">
      <div className="col-md-3">
        <div className="phone-call2 d-flex justify-content-start align-items-center flex-row gap-2">
          <div className="icon">
            <img src='/images/icons/call-icon2.svg' alt="image" className="img-fluid mt-1" />
          </div>
          <div className="number">
            <span>{t('topbar.Call Us Now')}</span>
            <h5><a href="tel:+961 70725443">{t('navbar.PhoneNumber')}</a></h5>
          </div>
        </div>
      </div>
      <div className="col-md-6 text-center">
        <div className="appontment">
          <h6>{t('topbar.Get Your')} <Link to={'/contact'}>{t('topbar.Appoinment')}</Link> {t('topbar.Now')}</h6>
        </div>
      </div>
      <div className="col-md-3 d-flex justify-content-end">
        <div className="phone-call2 d-flex justify-content-start align-items-center flex-row gap-2">
          <div className="icon">
            <img src='/images/icons/envelope2.svg' alt="image" className="img-fluid mt-1" />
          </div>
          <div className="number">
            <span>{t('topbar.Email Now')}</span>
            <h5><a href="mailto:info@allwaylaw.com">info@allwaylaw.com</a></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
   
    </>
  )
}

export default Topbar