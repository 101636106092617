import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.css";
import "./assets/css/all.css";
import "./assets/css/boxicons.min.css";
import "./assets/css/bootstrap-icons.css";
import "./assets/css/swiper-bundle.css";
import "./assets/css/style.css";

import Homepage from "./components/main/Homepage";
import About from "./components/main/About";
import Team from "./components/main/Team";
import TeamDetails from "./components/main/TeamDetails";
import Practice from "./components/main/Practice";
import PracticeDetails from "./components/main/PracticeDetails";
import Blog from "./components/main/Blog";
import BlogDetail1 from "./components/main/BlogDetail1";
import BlogDetail2 from "./components/main/BlogDetail2";
import Careers from "./components/main/Careers";
import TermsOfUse from './components/main/TermsOfUse'
import PrivacyPolicy from './components/main/PrivacyPolicy'

import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";

import enTranslations from "./data/en.json";
import arTranslations from "./data/ar.json";
import trTranslations from "./data/tr.json";
import Contact from "./components/main/Contact";
import { Toaster } from "react-hot-toast";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    ar: { translation: arTranslations },
    tr: { translation: trTranslations },
  },
  lng: "en", // Default language
  interpolation: { escapeValue: false }, // React already does escaping
});

function App() {
  const { t } = useTranslation();
  return (
    <div>
      <Toaster />
      <Routes>
        <>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team/:username" element={<TeamDetails />} />
          <Route path="/practice" element={<Practice />} />
          <Route path="/practice/:username" element={<PracticeDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/Commercial&Coorporate" element={<BlogDetail1 />} />
          <Route path="/Arbitration" element={<BlogDetail2 />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<TermsOfUse />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </>
      </Routes>
    </div>
  );
}

export default App;
